import { ReplaySubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import StringUtils from 'src/app/utilities/string-utils';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedSessionService {
  private readonly tokenKey = 'token';
  private readonly targetPageKey = 'targetPage';
  private readonly customerIdKey = 'customerId';
  private readonly agencyIdKey = 'agencyId';
  private readonly documentReferrerKey = 'documentReferrer';

  private customerIdReplaySubject: ReplaySubject<string> = new ReplaySubject(1);
  private agencyIdReplaySubject: ReplaySubject<string> = new ReplaySubject(1);
  private tokenReplaySubject: ReplaySubject<string> = new ReplaySubject(1);

  constructor(public jwtHelper: JwtHelperService) {
    console.log('in authenticated session service');
    if (this.isAuthenticated()) {
      this.tokenReplaySubject.next(this.getToken());
    } else {
      console.log('Is authenticated failed');
    }
  }

  /*
   * Get the jwt token from the session storage to use to authenticate requests made to provisioning
   */
  public getToken(): string {
    console.log('Auth Session get token');
    return sessionStorage.getItem(this.tokenKey);
  }

  /*
   * Set the jwt token into session storage so it can be recovered in future reloads of the page while it is still valid
   */
  public setToken(token: string): void {
    if (token) {
      console.log('Auth Session set token' + token);
      if (token.toLowerCase().startsWith('bearer ')) {
        token = token.substring('bearer '.length);
      }
      sessionStorage.setItem(this.tokenKey, token);
      this.tokenReplaySubject.next(token);
    }
  }

  /*
   * The original page the user was attempting to reach before being redirected for authentication
   */
  public getTargetPage(): string {
    console.log('=============Auth session get the root page?');
    return sessionStorage.getItem(this.targetPageKey);
  }

  /*
   * Set the page the user is attempting to reach before redirecting for authentication
   */
  public setTargetPage(targetPage: string): void {
    console.log('set target parge ' + targetPage);
    sessionStorage.setItem(this.targetPageKey, targetPage);
  }

  public getCustomerId(): string {
    console.log('get customer id');
    return sessionStorage.getItem(this.customerIdKey);
  }

  public getCustomerIdObservable(): Observable<string> {
    console.log('get customer id');
    return this.customerIdReplaySubject.asObservable();
  }

  public setCustomerId(customerId: string): void {
    console.log('set customer id:' + customerId);
    // temp workaround for https://dev.azure.com/msi-cie/Location-Service/_workitems/edit/573197
    // this might be removed once i18n with current CCAdmin language picker is implemented
    customerId = StringUtils.sanitizeCustomerID(customerId);
    this.customerIdReplaySubject.next(customerId);
    sessionStorage.setItem(this.customerIdKey, customerId);
  }

  public getAgencyId(): string {
    return sessionStorage.getItem(this.agencyIdKey);
  }

  public getAgencyIdObservable(): Observable<string> {
    return this.agencyIdReplaySubject.asObservable();
  }

  public setAgencyId(agencyId: string): void {
    this.agencyIdReplaySubject.next(agencyId);
    sessionStorage.setItem(this.agencyIdKey, agencyId);
  }

  public getDocumentReferrer(): string {
    console.log('get document referer');
    return sessionStorage.getItem(this.documentReferrerKey);
  }

  public setDocumentReferrer(documentReferrer: string): void {
    sessionStorage.setItem(this.documentReferrerKey, documentReferrer);
  }

  public get token$(): Observable<string> {
    return this.tokenReplaySubject.asObservable();
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    // Check whether the token is expired and return
    // true or false
    console.log('Is authenticated called' + token);
    return !this.jwtHelper.isTokenExpired(token);
  }
}
