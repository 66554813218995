import { Toast, ToastService } from '@msi/cobalt';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class SuccessInterceptorService implements HttpInterceptor {
  // Admin makes non-user initiated requests and we don't want the success message to appear unannounced
  public static readonly IGNORE_HEADER = 'x-passive-request-app-use-only';
  private toastIns: Toast;
  constructor(private toastService: ToastService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignore = req.headers.has(SuccessInterceptorService.IGNORE_HEADER);
    const request = req.clone({
      headers: req.headers.delete(SuccessInterceptorService.IGNORE_HEADER),
    });

    return next.handle(request).pipe(
      tap({
        next: (response: HttpResponse<any>) => {
          if (response instanceof HttpResponse) {
            const successCode = response.status;
            let message = '';
            switch (successCode) {
              case 201:
              case 202:
                message = 'Changes Saved';
                break;
              case 204:
                message = 'Configuration Deleted';
                break;
            }

            if (message && !ignore) {
              this.toastIns = this.toastService.success(`${message}`, null, { autoDismiss: 2000 });
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          let errorDescription = null;
          if (err.headers && err.headers.has('FailureReason')) {
            errorDescription = err.headers.get('FailureReason');
          }

          if (!errorDescription) {
            switch (err.status) {
              case 401:
                errorDescription = 'Unauthenticated';
                break;
              case 403:
                errorDescription = 'Missing CCAdmin permission';
                break;
              case 409:
                errorDescription = 'Duplicate record exists.';
                break;
              case 500:
                errorDescription = 'Add/Update/Delete data failed, please contact your administrator.';
                break;
              default:
                // Currently we can't show the server's reason for the failure due to the error response being in a header
                // not exposed through CORS
                errorDescription = 'Failed to Add/Update/Delete data.';
                break;
            }
          }

          this.toastIns = this.toastService.error(`${errorDescription}`, null, { autoDismiss: 5000 });
        },
      })
    );
  }
}
