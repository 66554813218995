import { HttpUser } from './../http-models/http-user';
export class User {
  public static readonly READ_UNITS_PERMISSION = 'Read Units';
  public static readonly WRITE_UNITS_PERMISSION = 'Write Units';
  public static readonly READ_LOCATION_POLICY_PERMISSION = 'Read Location Policy';
  public static readonly WRITE_LOCATION_POLICY_PERMISSION = 'Write Location Policy';

  private readonly knownServiceNames = ['location_service_provisioning_v2', 'location_service_provisioning'];
  private permissions: Set<string> = new Set();

  public static fromHttpClient(httpUser: HttpUser): User {
    const user = new User();
    httpUser.provisioningPermissions.forEach((permission) => {
      user.permissions.add(permission);
    });

    return user;
  }

  constructor() {}

  public canReadUnits(): boolean {
    return this.permissions.has(User.READ_UNITS_PERMISSION);
  }

  public canWriteUnits(): boolean {
    return this.permissions.has(User.WRITE_UNITS_PERMISSION);
  }

  public canReadLocationPolicy(): boolean {
    return this.permissions.has(User.READ_LOCATION_POLICY_PERMISSION);
  }

  public canWriteLocationPolicy(): boolean {
    return this.permissions.has(User.WRITE_LOCATION_POLICY_PERMISSION);
  }

  public hasPermissions(requiredPermissions: string[]): boolean {
    // Check if every required permission is present in the User's current permissions
    return requiredPermissions.every((rp) => this.permissions.has(rp));
  }
}
