export class Oauth {
  constructor({ idmHost, authPath, clientId, scopes, redirectUri, responseType }) {
    this._idmHost = idmHost;
    this._authPath = authPath;
    this._clientId = clientId;
    this._scopes = scopes;
    this._redirectUri = redirectUri;
    this._responseType = responseType;
  }

  private _idmHost: string;
  private _authPath: string;
  private _clientId: string;
  private _scopes: string;
  private _redirectUri: string;
  private _responseType: string;

  public get idmHost(): string {
    return this._idmHost;
  }

  public get authPath(): string {
    return this._authPath;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public get scopes(): string {
    return this._scopes;
  }

  public get redirectUri(): string {
    return this._redirectUri;
  }

  public get responseType(): string {
    return this._responseType;
  }
}
