import { AuthenticationModule } from './core/authentication/authentication.module';
import { MsiCommonModule, ModalService } from '@msi/cobalt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
// EnvironmentVariableService is a singleton so it's provided at the root Angular modules
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { Routes, RouterModule } from '@angular/router';
import { FailedAuthenticatedPageComponent } from './core/authentication/failed-authenticated-page/failed-authenticated-page.component';
import { ConfirmAuthGuardService } from './core/authentication/services/confirm-authentication-guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SuccessInterceptorService } from './modules/common/http-interceptors/success-interceptor.service';
import { IamAuthGrantConfigToken, UserAuthenticationModule } from '@msi/commandcentral-common-header';
import { getAppSpecificIamConfig } from './config/app.config';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const routes: Routes = [
  { path: 'unauthorized', component: FailedAuthenticatedPageComponent },
  {
    path: 'provisioning',
    loadChildren: () => import('./modules/location-provisioning/location-provisioning.module').then((m) => m.LocationProvisioningModule),
    canLoad: [ConfirmAuthGuardService],
    canActivate: [ConfirmAuthGuardService],
  },
  { path: '', redirectTo: 'provisioning', pathMatch: 'full' },
  { path: 'adminuiv2', redirectTo: 'provisioning', pathMatch: 'full' },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    MsiCommonModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return sessionStorage.getItem('token');
        },
      },
    }),
    RouterModule.forRoot(routes),
    UserAuthenticationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    JwtHelperService,
    ModalService,
    { provide: HTTP_INTERCEPTORS, useClass: SuccessInterceptorService, multi: true },
    { provide: IamAuthGrantConfigToken, useFactory: getAppSpecificIamConfig },
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
