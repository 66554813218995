export default class StringUtils {
  static asEmptyIfNull(input: string): string {
    return !input ? '' : input;
  }

  // input 'calgarypolice.ca&language=en[no]<bueno>' returns 'calgarypolice.ca'
  // https://dev.azure.com/msi-cie/Location-Service/_wiki/wikis/Location-Service.wiki/33100/Field-Resource-important-constraints-validation
  static sanitizeCustomerID(input: string): string {
    // ASCII & URL encoded forms of special chars
    const regex = /[!*'();:&=+$,/?%#[\]<>"]|%21|%2A|%27|%28|%29|%3B|%3A|%26|%3D|%2B|%24|%2C|%2F|%3F|%25|%23|%5B|%5D|%3C|%3E/g;
    // split the string at the 1st occurrence of any special char and return the 1st part
    return StringUtils.asEmptyIfNull(input).split(regex)[0];
  }
}
