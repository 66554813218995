export class CcAdmin {
  constructor({ api, origin, getMe, getUsers, getCustomerServices }) {
    this._api = api;
    this._origin = origin;
    this._getMe = getMe;
    this._getUsers = getUsers;
    this._getCustomerServices = getCustomerServices;
  }

  private _api: string;
  private _origin: string;
  private _getMe: string;
  private _getUsers: string;
  private _getCustomerServices: string;

  public get api(): string {
    return this._api;
  }

  public get origin(): string {
    return this._origin;
  }

  public get getMe(): string {
    return this._getMe;
  }

  public get getUsers(): string {
    return this._getUsers;
  }

  public get getCustomerServices(): string {
    return this._getCustomerServices;
  }
}
