import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'msi-failed-authenticated-page',
  templateUrl: './failed-authenticated-page.component.html',
  styleUrls: ['./failed-authenticated-page.component.scss'],
})
export class FailedAuthenticatedPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log('Local unauthenticated');
  }
}
