import {IamAuthGrantConfig} from '@msi/commandcentral-common-header';

const CLIENT_ID = 'CQAdmin';
const SCOPES = ['CCAdmin'];

export function getAppSpecificIamConfig(): IamAuthGrantConfig {
  console.log('Get iam config');
  const config: IamAuthGrantConfig = {
    scopes: SCOPES,
    clientId: CLIENT_ID,
    developmentMode: false,
    singlePreprod: true
  };
  return config;
}
