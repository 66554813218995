import { MsiCommonModule } from '@msi/cobalt';
import { FailedAuthenticatedPageComponent } from './failed-authenticated-page/failed-authenticated-page.component';
import { IdmAuthenticatedPageComponent } from './idm-authenticated-page/idm-authenticated-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTokenService } from '@msi/commandcentral-common-header';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [IdmAuthenticatedPageComponent, FailedAuthenticatedPageComponent],
  imports: [CommonModule, MsiCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [HeaderTokenService],
})
export class AuthenticationModule {}
