/* Copyright 2022 Motorola Solutions Inc. All Rights Reserved. */
export class Features {
    constructor({displayPersonAssignment, namingSchemeFeature}) {
      this._displayPersonAssignment = displayPersonAssignment;
      this._namingSchemeFeature = namingSchemeFeature;
    }
    private _displayPersonAssignment: string;
    private _namingSchemeFeature: string;

    public get displayPersonAssignment(): string {
      return this._displayPersonAssignment;
    }

    public get namingSchemeFeature(): string {
      return this._namingSchemeFeature;
    }
  }
