export class AdminApi {
  constructor({ api }) {
    this._api = api;
  }

  private _api: string;

  public get api(): string {
    return this._api;
  }
}
