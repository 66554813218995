import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CcAdmin } from '../models/cc-admin';
import { Oauth } from '../models/oauth';
import { AdminApi } from '../models/admin-api';
import { EnvironmentVariables } from '../models/environment-variables';
import { ReplaySubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpEnvironmentVariables } from '../http-models/http-environment-variables';
import { Features } from '../models/features';
import { EnvironmentId } from '../models/environment-config';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentVariableService {
  constructor(private httpClient: HttpClient) {
    this.getEnvironmentVariables();
  }

  private _environmentVariables: EnvironmentVariables;
  private _observableEnvironmentVariables: ReplaySubject<EnvironmentVariables> = new ReplaySubject(1);

  public get environmentVariables(): EnvironmentVariables {
    return this._environmentVariables;
  }

  // Returns an observable who will emit its value once it receives it.
  public get observableEnvironmentVariables(): Observable<EnvironmentVariables> {
    return this._observableEnvironmentVariables.asObservable();
  }

  public getEnvironmentId(): EnvironmentId {
    const originUrl = window.location.host;
    if (originUrl.includes('localhost')) {
      return EnvironmentId.LOCAL;
    } else if (originUrl.includes(EnvironmentId.DEV)) {
      return EnvironmentId.DEV;
    } else if (originUrl.includes(EnvironmentId.STAGE)) {
      return EnvironmentId.STAGE;
    } else if (originUrl.includes(EnvironmentId.CA)) {
      return EnvironmentId.CA;
    } else {
      return EnvironmentId.USGOV;
    }
  }

  private getEnvironmentVariables(): void {
    if (environment.isProduction) {
      // When running through Docker use the deployment time's environmentVariables.json config file to set the environment variables
      console.log('Running through Docker, using deployment time environment variables');
      this.retrieveProductionEnvironmentVariables();
    } else {
      // When running locally use Angular's environment variables set in the root directory's environment folder.
      console.log('Running locally, using environment variables set in the root directory environment folder');
      this.retrieveDevEnvironmentVariables();
    }
  }

  private retrieveProductionEnvironmentVariables(): void {
    this.httpClient.get('./config/environmentVariables.json').subscribe(
      (environmentVariables: HttpEnvironmentVariables) => {
        this._environmentVariables = EnvironmentVariables.fromHttpClient(environmentVariables);
        console.log('Env vars: ' + this.environmentVariables);
        this._observableEnvironmentVariables.next(this._environmentVariables);
      },
      () => {
        console.log('Encountered error retrieving environmentVariables json');
      }
    );
  }

  private retrieveDevEnvironmentVariables(): void {
    const ccAdmin = new CcAdmin(environment.environmentVariables.ccAdmin);
    const oauth = new Oauth(environment.environmentVariables.oauth);
    const adminApi = new AdminApi(environment.environmentVariables.adminApi);
    const features = new Features(environment.environmentVariables.features);

    setTimeout(() => {
      this._environmentVariables = new EnvironmentVariables(oauth, adminApi, ccAdmin, features);
      this._observableEnvironmentVariables.next(this._environmentVariables);
    }, 1000);
  }
}
