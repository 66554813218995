import { HttpEnvironmentVariables } from '../http-models/http-environment-variables';
import { Oauth } from './oauth';
import { AdminApi } from './admin-api';
import { CcAdmin } from './cc-admin';
import { Features } from './features';

export class EnvironmentVariables {
  constructor(public oauth: Oauth, public adminApi: AdminApi, public ccAdmin: CcAdmin, public features: Features) {}

  public static fromHttpClient(httpEnvironmentVariables: HttpEnvironmentVariables): EnvironmentVariables {
    return new EnvironmentVariables(
      new Oauth(httpEnvironmentVariables.oauth),
      new AdminApi(httpEnvironmentVariables.adminApi),
      new CcAdmin(httpEnvironmentVariables.ccAdmin),
      new Features(httpEnvironmentVariables.features)
    );
  }
}
