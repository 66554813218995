import { AuthenticatedSessionService } from './../../authentication/services/authenticated-session.service';
import { ReplaySubject, Observable, combineLatest } from 'rxjs';
import { HttpUser } from './../http-models/http-user';
import { EnvironmentVariables } from './../../environment/models/environment-variables';
import { EnvironmentVariableService } from 'src/app/core/environment/services/environment-variable.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: User;
  private _observableUser: ReplaySubject<User> = new ReplaySubject(1);

  constructor(
    private httpClient: HttpClient,
    private authenticatedSessionService: AuthenticatedSessionService,
    private environmentVariableService: EnvironmentVariableService
  ) {
    console.log('get to user service: ' + this.environmentVariableService.observableEnvironmentVariables);
    combineLatest([this.environmentVariableService.observableEnvironmentVariables, this.authenticatedSessionService.token$])
      .pipe(take(1))
      .subscribe(([envVariables]) => {
        if (envVariables && envVariables.adminApi.api) {
          console.log('call get user: ' + envVariables.adminApi.api);
          this.retrieveUser(envVariables);
        } else {
          console.log('Error in Environment: ', envVariables);
        }
      });
  }

  public get user(): User {
    return this._user;
  }

  public get user$(): Observable<User> {
    return this._observableUser.asObservable();
  }

  private retrieveUser(environmentVariables: EnvironmentVariables): void {
    console.log('Retrieve User: ' + environmentVariables.adminApi.api);
    this.httpClient
      .get(`${environmentVariables.adminApi.api}/commonlocation/v1/config/ccadminuser`, { headers: this.getDefaultHeaders() })
      .subscribe(
        (user: HttpUser) => {
          this._user = User.fromHttpClient(user);
          console.log('This user is:' + this._user);
          this._observableUser.next(this._user);
        },
        (error) => {
          console.log('Encountered error retrieving /commonlocation/v1/config/ccadminuser json: ', error);
        }
      );
  }

  private getDefaultHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    console.log('get default headers of user service');
    headers = headers.append('Authorization', 'Bearer ' + this.authenticatedSessionService.getToken());

    return headers;
  }
}
