// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  isProduction: true,
  environmentVariables: {
    oauth: {
      idmHost: undefined,
      authPath: undefined,
      clientId: undefined,
      scopes: undefined,
      redirectUri: undefined,
      responseType: undefined,
    },
    adminApi: {
      api: undefined,
    },
    ccAdmin: {
      api: undefined,
      origin: undefined,
      getMe: undefined,
      getUsers: undefined,
      getCustomerServices: undefined,
    },
    features: {
      displayPersonAssignment: undefined,
      namingSchemeFeature: undefined,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
